import myRequest from '../http/index.js'
// 活动详情
export function signUpViewAPI(params){
    return myRequest({
        url:"index.php/index/task/signUpView",         
        params,
        method:"post"
      })
}
// 报名下单
export function signUpAPI(params){
  return myRequest({
      url:"index.php/index/task/orderZ",         
      params,
      method:"post"
    })
}
// 订单详情
export function orderNowinfoAPI(params){
  return myRequest({
      url:"index.php/index/task/orderView",         
      params,
      method:"get"
    })
}
// 默认用户信息回显
export function personinfoAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/canceUser",         
      params,
      method:"get"
    })
}
// 微信扫码支付
export function wxpayAPI(params){
  return myRequest({
      url:"index.php/index/wechatpay/payCodeOrder",         
      params,
      method:"post"
    })
}
// 微信支付查询(更新订单)
export function orderStatusAPI(params){
  return myRequest({
      url:"index.php/index/wechatpay/getOrderStatus",         
      params,
      method:"post"
    })
}
// 释放优惠券
export function releaseCouponAPI(params){
  return myRequest({
      url:"index.php/index/task/releaseCoupon",         
      params,
      method:"post"
    })
}
// 修改订单
export function updateOrderAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/updateOrder",         
      params,
      method:"post"
    })
}

// 支付宝
export function alipayFormAPI(params){
  return myRequest({
      url:"index.php/index/alipay/alipayForm",         
      params,
      method:"get"
    })
}