<template>
    <div>
        <el-dialog :visible.sync="show" :title="name" width="850px" >
            <div class="user">
                <h4>{{name}}</h4>
                <div class="text" v-html="content">
                
                </div>
            </div>
        </el-dialog>    
    </div>
</template>

<script>
import {aboutAPI} from "@/api/footnav"
export default {
	    data () {
	      return {
	        content:"",
            name:"",
            show:false,
           
	      }
	    },
        methods:{
			getabout(i){
				aboutAPI().then(res=>{
					this.content=res.cateList[i].content
					this.name=res.cateList[i].name
				})
			}
		}
	  }
</script>

<style lang="scss" scope>
	.user{		
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 24px;
			font-weight: 500;
			color: #333333;
			 
			line-height: 45px;
			text-align: center;
		}
		img{
            width: 100%;
        }
	}

</style>
