import myRequest from '../http/index.js'
// 活动详情
export function goodsViewAPI(params){
    return myRequest({
        url:"index.php/index/index/goodsView",         
        params,
        method:"get"
      })
}
// 活动咨询
export function questionAnswerListAPI(params){
    return myRequest({
        url:"index.php/index/index/userQuestionAnswerList",         
        params,
        method:"get"
      })
}
// 接口：活动问答
export function questionAddAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/userQuestionAnswerAdd",         
      params,
      method:"post"
    })
}
// 活动详情评论列表
export function goodsCommentListAPI(params){
  return myRequest({
      url:"index.php/index/index/goodsComment",         
      params,
      method:"get"
    })
}
// 活动详情更多报名人
export function moreNumberAPI(params){
  return myRequest({
      url:"index.php/index/index/priceNumber",         
      params,
      method:"post"
    })
}
// 活动行程备注
export function travelnotesAPI(params){
  return myRequest({
      url:"index.php/index/article/travelnotes",         
      params,
      method:"post"
    })
}
// 出行清单/报名须知
export function tripListAPI(params){
  return myRequest({
      url:"index.php/index/task/tripList",         
      params,
      method:"post"
    })
}
//  
export function getActivityCouponList(params){
  return myRequest({
      url:"index.php/index/Coupon/getGoodsCoupon",         
      params,
      method:"post"
    })
}
//  
export function receiveCouponAPI(params){
  return myRequest({
      url:"index.php/index/Userinfo/receiveCoupon",         
      params,
      method:"post"
    })
}